<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="list-contetnt-header">
        <div class="list-content-select">
          <div class="list-title">会长账号:</div>
          <el-input
            placeholder="请填写会长账户"
            suffix-icon="el-icon-user"
            v-model="inputValue.preName"
          >
          </el-input>
          <div class="list-title">公司名称:</div>
          <el-input
            placeholder="请填写公司名称"
            suffix-icon="el-icon-office-building"
            v-model="inputValue.companyName"
          >
          </el-input>
          <div class="list-title">公司账户:</div>
          <el-input
            placeholder="请填写公司账户"
            suffix-icon="el-icon-collection-tag"
            v-model="inputValue.companyAccount"
          >
          </el-input>
          <div class="list-title">企业信用代码:</div>
          <el-input
            placeholder="请填写企业信用代码"
            suffix-icon="el-icon-c-scale-to-original"
            v-model="inputValue.enterprise"
          >
          </el-input>
        </div>
        <div class="list-content-btn">
          <el-button type="info" plain @click="queryData('query')"
            >查询</el-button
          >
        </div>
        <div class="list-contetn-add">
          <el-button type="info" plain @click="addPresidents"
            >添加工会信息</el-button
          >
        </div>
      </div>
      <tables
        :ListArr="unionListArr"
        :tableList="unionTableList"
        :tablePagination="unionPagination"
        :editRow="editUnionRow"
        :deletes="deleteUnion"
        :currentChange="unionCurrentChange"
        :sizeChange="unionSizeChange"
        :loading="unionLoading"
        :isBtn="unionBtn"
        :use="isPressidentsUse"
      ></tables>
    </el-card>
    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="修改工会信息"
      :visible.sync="dialogVisibeeUnion"
      top="30vh"
      :show-close="false"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12">
          <el-form
            id="from"
            :model="union"
            ref="ruleForm"
            label-width="100px"
            :rules="rules"
          >
            <!-- <input type="hidden" v-model="union.id" /> -->
            <el-form-item label="昵称-账号:">
              <el-select
                v-model="union.unionName"
                placeholder="请选择"
                class="union-select"
              >
                <el-option
                  v-for="(item, index) in nOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公司名称:" prop="companyName">
              <el-input v-model="union.companyName"></el-input>
            </el-form-item>
            <el-form-item label="公司法人身份ID:" prop="companyAccount">
              <el-input v-model="union.companyAccount"></el-input>
            </el-form-item>
            <el-form-item label="企业信用代码:">
              <el-input v-model="union.enterpriseCode"></el-input>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="union.remark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row id="anniu22" type="flex">
        <el-col>
          <el-button type="success" @click="canceledits('ruleForm')"
            >取消</el-button
          >
          <el-button type="primary" @click="submitFormUnion('ruleForm')"
            >确认</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import unionList from '../laborunion';
import tables from '../laborunion/table';
import Combination from '@/components/Combination.vue';
import {
  getUnionLists,
  deleteUnions,
  getEmployeeList,
  editUnion,
  unionIsUse,
} from '@/service/moudules/platformApi';
import { checkCompanyName, checkCompanyAccount } from '@/util/proofread';
import { getCookie } from '@/util/userLoginFn';
export default {
  components: {
    unionList,
    tables,
    Combination,
  },
  data() {
    return {
      getUnionLists,
      getCookie,
      deleteUnions,
      editUnion,
      unionIsUse,
      checkCompanyName,
      checkCompanyAccount,
      sigleuserUnion: {},
      title: '工会信息',
      unionLoading: false,
      unionTableList: [
        {
          name: '工会ID',
          prop: 'id',
        },
        {
          name: '公司名称',
          prop: 'name',
        },
        {
          name: '公司法人身份ID',
          prop: 'code',
        },
        {
          name: '企业信用代码',
          prop: 'empNo',
        },
        {
          name: '会长账号',
          prop: 'stuffName',
        },
        {
          name: '余额',
          prop: 'balance',
        },
        {
          name: '备注',
          prop: 'remark',
        },
        {
          name: '状态',
          prop: 'status',
        },
      ],
      union: {
        unionName: null,
        companyName: null,
        companyAccount: null,
        enterpriseCode: null,
        remark: null,
        ids: null,
      },
      nOptions: [],
      unionTitle: '工会信息',
      editTitle: '',
      inputValue: {
        preName: null,
        companyName: null,
        companyAccount: null,
        enterprise: null,
      },
      unionPagination: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      unionListArr: [],
      dialogVisibeeUnion: false,
      dialogVisibeeEdit: false,
      unionRoleType: null,
      unionTitles: '',
      isWho: 'isUnion',
      isUnionClean: false,
      unionArr: [],
      unionBtn: 'unionBtn',
      rules: {
        companyName: [
          { validator: checkCompanyName, trigger: 'blur', required: true },
        ],
        companyAccount: [
          { validator: checkCompanyAccount, trigger: 'blur', required: true },
        ],
      },
    };
  },
  methods: {
    //页数修改重新加载
    unionSizeChange(val) {
      this.unionPagination.pageSize = val;
      this.queryData();
    },
    unionCurrentChange(val) {
      this.unionPagination.currentPage = val;
      this.queryData();
    },
    // addEmployee() {},
    canceleditUnion() {
      this.dialogVisibeeEdit = false;
      this.queryData('edit');
    },
    editUnionRow(row) {
      let { code, name, empNo, remark, presidentId, stuffName, id } = row;
      this.union.unionName =
        stuffName == ''
          ? null
          : this.unionArr.find((item) => item.stuffName === stuffName)
              .stuffName;
      this.union.companyName = name;
      this.union.companyAccount = code;
      this.union.enterpriseCode = empNo;
      this.union.remark = remark;
      this.union.ids = id;
      this.dialogVisibeeUnion = true;
    },
    closeUnionDialog() {
      this.dialogVisibleeditUnion = false;
      this.isUnionClean = true;
    },
    canceledits(roleForm) {
      this.dialogVisibeeUnion = false;
      this.queryData();
      this.$refs[roleForm].resetFields();
    },

    //添加添加
    addPresidents() {
      this.$router.push({
        path: '/addPresident',
      });
    },
    async isPressidentsUse(row) {
      console.log(row, 'row');
      try {
        let { id, flag } = row;
        let params = {
          id,
          isFlag: flag === 0 ? 1 : 0,
        };

        let { code, data, msg } = await unionIsUse(params);

        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: flag === 0 ? '启用成功' : '停用成功',
        });

        this.queryData();
      } catch (error) {
        console.log(error);
      }
    },
    async getEmployeeLists() {
      try {
        let params = `?pageNo=1&pageSize=200&type=1`;

        const { code, msg, data } = await getEmployeeList(params);
        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.nOptions = data.records
          ? data.records.map((item, index) => {
              return {
                value: item.stuffId,
                label: item.stuffName,
              };
            })
          : [];
      } catch (error) {
        console.log(error);
      }
    },

    async deleteUnion(row) {
      try {
        const { id } = row;

        let params = {
          id,
          sessionId: this.getCookie('sessionId'),
        };

        this.$confirm('此操作将删除该条信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let { data, code, msg } = await deleteUnions(params);
            if (code !== 200 && msg !== 'success') {
              this.$message({
                type: 'error',
                message: msg,
              });
              return false;
            }

            this.$message({
              type: 'success',
              message: '删除信息成功',
            });
            this.queryData();
          })
          .catch(() => {
            this.queryData();
            return false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    async getUnionList() {
      try {
        let parasm = {
          code: '',
          empNo: '',
          name: '',
          presidentId: '',
          stuffName: '',
        };

        let { data, code, msg } = await llodkl(params);
      } catch (error) {
        console.log(error);
      }
    },
    //表格格式化
    codeFormatter(row, column, cellValue, index) {
      return cellValue == '' ? '空' : cellValue;
    },
    updateList() {
      this.closeUnionDialog = false;
      this.queryData();
    },

    async submitFormUnion() {
      try {
        let {
          companyName,
          companyAccount,
          enterpriseCode,
          unionName,
          remark,
          ids,
        } = this.union;
        let findRes = this.unionArr.find(
          (item) => item.stuffName === this.union.unionName
        );
        console.log(findRes, 'findRes');
        let params = {
          presidentId: findRes ? findRes.presidentId : unionName,
          name: companyName,
          code: companyAccount,
          empNo: enterpriseCode,
          remark: remark,
          id: ids,
          sessionId: this.getCookie('sessionId'),
        };

        let res = await editUnion(params);
        let { data, code, msg } = res;

        if (code !== 200 && msg !== 'success') {
          this.$message({
            type: 'error',
            message: msg,
          });

          return false;
        }

        this.$message({
          type: 'success',
          message: '修改数据成功',
        });
        this.dialogVisibeeUnion = false;
        this.queryData();
      } catch (error) {
        console.log(error, 'error');
      }
    },

    //点击查询数据
    async queryData(isQuery = 'get') {
      try {
        this.unionLoading = true;
        let {
          preName,
          companyName,
          companyAccount,
          enterprise,
        } = this.inputValue;

        let { pageSize, currentPage } = this.unionPagination;

        let params = {
          code: companyAccount,
          empNo: enterprise,
          name: companyName,
          stuffName: preName,
          sessionId: this.getCookie(['name', 'sessionId']),
          pageSize,
          pageNo: currentPage,
        };
        let { code, data, msg, total } = await getUnionLists(params);
        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          this.unionLoading = false;
          return false;
        }
        console.log(code, 'code11');
        this.unionListArr = data.records
          ? data.records.map((item) => {
              item.status = item.flag === 1 ? '停用' : '启用';

              return item;
            })
          : [];
        this.unionArr = data.records ? data.records : [];
        this.unionPagination.total = data.total ? data.total : 0;
        console.log('会长');
        this.$message({
          type: 'success',
          message: isQuery == 'query' ? '查询会长列表成功' : '获取会长列表成功',
        });
        this.unionLoading = false;
      } catch (error) {
        this.unionListArr = [];
        this.unionPagination.total = 0;
        this.unionLoading = false;
        console.log(error);
      }
    },
  },
  created() {
    this.queryData();
    this.getEmployeeLists();
  },
};
</script>
<style lang="less">
.list-contetnt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  .list-content-select {
    display: flex;
    align-items: center;
    .list-title {
      margin-right: 10px;
      width: 60%;
      font-size: 14px;
      padding: 10px;
    }
  }
  .list-content-btn {
    margin: 0 10px;
  }
}
.union-select {
  width: 100%;
}
</style>
